
.hk-xp-bar {
	display: flex;
	justify-content: space-between;

	&__level {
		text-align: center;

		// &:first-child {
		// 	padding-right: 10px;
		// }
		// &:last-child {
		// 	padding-left: 10px;
		// }
	}

	.q-linear-progress {
		.value {
			font-size: 12px;
		}
	}
}
